import store from '@/store';
//import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutations } from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/public-survey/:id?",
    name: "public-survey",
    component: () => import("@/views/DynamicSurvey/PublicSurvey.vue"),
  },
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/forgot-pass",
        name: "forgot-pass",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
      },
      {
        path: "/resetPassword/:token",
        name: "resetPassword",
        component: () => import("@/views/authentication/ResetPassword.vue"),
      },
      {
        path: "/password-expire-reset",
        name: "ExpirePasswordReset",
        component: () =>
          import("@/views/authentication/ResetExpirePassword.vue"),
      },
      {
        path: "/sign-switch",
        name: "sign-switch",
        component: () => import("@/views/authentication/Switcher.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/SignUp.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dash",
    component: () => import("@/layout/DashBoard.vue"),
    children: [
      {
        path: "/dash",
        name: "dashboard",
        component: () => import("@/views/DashBoard/Dashboard.vue"),
      },
      {
        path: "/beneficiary",
        name: "beneficiary",
        component: () => import("@/views/Beneficiary/Beneficiary.vue"),
      },
      {
        path: "/save-beneficiary/:id?/:type?",
        name: "save-beneficiary",
        component: () => import("@/views/Beneficiary/BeneficiaryForm.vue"),
      },
      {
        path: "/beneficiary-profile/:id",
        name: "beneficiary-profile",
        component: () => import("@/views/Beneficiary/BeneficiaryProfile.vue"),
      },
      {
        path: "/event",
        name: "event",
        component: () => import("@/views/Event/Event.vue"),
      },
      {
        path: "/save-event/:id?",
        name: "save-event",
        component: () => import("@/views/Event/EventForm.vue"),
      },
      {
        path: "/map",
        name: "map",
        component: () => import("@/views/Beneficiary/BeneficiaryMap.vue"),
      },
      {
        path: "/output",
        name: "output",
        component: () => import("@/views/Output/Output.vue"),
      },
      {
        path: "/activity",
        name: "activity",
        component: () => import("@/views/Activity/Activity.vue"),
      },
      {
        path: "/task",
        name: "task",
        component: () => import("@/views/Task/TaskList.vue"),
      },
      {
        path: "/ia-partner",
        name: "ia-partner",
        component: () => import("@/views/IaPartner/IaPartner.vue"),
      },
      {
        path: "/occupation",
        name: "occupation",
        component: () => import("@/views/Occupation/Occupation.vue"),
      },
      {
        path: "/event-reports",
        name: "event-reports",
        component: () => import("@/views/Report/EventReport.vue"),
      },
      {
        path: "/knowledge-management",
        name: "knowledge-management",
        component: () => import("@/views/KnowledgeManagement/KnowledgeManagement.vue"),
      },
      {
        path: "/dynamic-survey",
        name: "dynamic-survey",
        component: () => import("@/views/DynamicSurvey/DynamicSurvey.vue"),
      },
      {
        path: "/question-list/:id?",
        name: "question-list",
        component: () => import("@/views/DynamicSurvey/SurveyQuestions.vue"),
      },
      {
        path: "/answer-sheet/:survey_id?/:mobile_no?",
        name: "answer-sheet",
        component: () => import("@/views/DynamicSurvey/SurveyAnswer.vue"),
      },
      {
        path: "/ip-work-plan",
        name: "ip-work-plan",
        component: () => import("@/views/IpWorkPlan/IpWorkPlan.vue"),
      },
      {
        path: "/quarterly-report",
        name: "quarterly-report",
        component: () => import("@/views/Activity/QuarterlyProgress.vue"),
      },
      {
        path: "/monthly-progress",
        name: "monthly-progress",
        component: () => import("@/views/Activity/ActivityProgress.vue"),
      },
      {
        path: "/tools-training",
        name: "tools-training",
        component: () => import("@/views/Enterprise/ToolsTraining.vue"),
      },
      {
        path: "/innovative-technology/:type?",
        name: "innovative-technology",
        component: () => import("@/views/Enterprise/InnovativeTechnology.vue"),
      },
      {
        path: "/business-development-services",
        name: "business-development-services",
        component: () => import("@/views/Enterprise/BusinessDevelopment.vue"),
      },
      {
        path: "/entrepreneurs-supply-chain",
        name: "/entrepreneurs-supply-chain",
        component: () => import("@/views/Enterprise/EntrepreneursSupplyChain.vue"),
      },
      {
        path: "/tourism/:type?",
        name: "tourism",
        component: () => import("@/views/Enterprise/Tourism.vue"),
      },
      {
        path: "/public-private-partnership",
        name: "/public-private-partnership",
        component: () => import("@/views/Enterprise/PublicPrivatePartnership.vue"),
      },
      {
        path: "/social-marketing-campaign",
        name: "/social-marketing-campaign",
        component: () => import("@/views/Enterprise/SocialMarketingCampaign.vue"),
      },
      {
        path: "/businesses-established",
        name: "/businesses-established",
        component: () => import("@/views/Enterprise/BusinessesEstablished.vue"),
      },
      {
        path: "/finance",
        name: "finance",
        component: () => import("@/views/Finance/finance.vue"),
      },
      {
        path: "/user-list",
        name: "user-list",
        component: () => import("@/views/user/users.vue"),
      },
      {
        path: "/beneficiary-report",
        name: "beneficiary-report",
        component: () => import("@/views/Report/BeneficiaryReport.vue"),
      },
      {
        path: "/narrative-report",
        name: "narrative-report",
        component: () => import("@/views/Report/NarrativeReport.vue"),
      },
    ],
  },
  {
    path: "/address",
    component: () => import("@/layout/Course.vue"),
    children: [
      {
        path: "address-list",
        name: "address list",
        component: () => import("@/views/course/search.vue"),
      },

      {
        path: "addressInfo",
        name: "New address",
        component: () => import("@/views/course/new.vue"),
      },
      {
        path: "mapping/agent",
        name: "map agent address ",

        component: () => import("@/views/course/mapping/MapTrainer.vue"),
      },
      {
        path: "unmapping/agent",
        name: "Unmap agent address ",

        component: () => import("@/views/course/mapping/MapAssessor.vue"),
      },
    ],
  },
  {
    path: "/company",
    name: "association",
    component: () => import("@/layout/Association.vue"),
    children: [
      {
        path: "company-list/",
        name: "entity list",
        component: () => import("@/views/Association/EntityList.vue"),
      },
      {
        path: "entity-list/:id",
        name: "single entity",
        component: () => import("@/views/Association/SingleEntity.vue"),
      },
      {
        path: "entity-employee/:id",
        name: "entity employee",
        component: () => import("@/views/Association/EntityEmployee.vue"),
      },
      {
        path: "employee-profile/:id",
        name: "entityEmployeeProfiles",
        component: () => import("@/views/Employee/EmployeeProfile.vue"),
      },
      {
        path: "dynamic-entity-list",
        name: "dynamic entity list",
        component: () => import("@/views/Association/DynamicEntityList.vue"),
      },
      {
        path: "new-company",
        name: "new entity",
        component: () => import("@/views/Association/NewEntity.vue"),
      },
      {
        path: "association-contracts",
        name: "association contracts",
        component: () => import("@/views/Association/AssociationContracts.vue"),
      },
      {
        path: "contract-detail/:id",
        name: "contract detail",
        component: () =>
            import("@/views/Association/AssociationContractView.vue"),
      },
      {
        path: "association-reg",
        name: "association-reg",
        component: () => import("@/views/Association/AddAssociation.vue"),
      },
      {
        path: "entity-target",
        name: "entity target",
        component: () => import("@/views/Association/EntityTarget.vue"),
      },
      {
        path: "pending-target",
        name: "pending target",
        component: () => import("@/views/Association/PendingTarget.vue"),
      },
      {
        path: "target-list",
        name: "target list",
        component: () => import("@/views/Association/TargetList.vue"),
      },
    ],
  },
  //Employee
  {
    path: "/employee",
    component: () => import("@/layout/Training_institute.vue"),
    children: [
      {
        path: "new_employee",
        name: "new institute",
        component: () => import("@/views/Training_Institute/NewInstitute.vue"),
      },
      {
        path: "employee_list",
        name: "institute search",
        component: () =>
            import("@/views/Training_Institute/InstituteSearch.vue"),
      },
      {
        path: "mapping",
        name: "mapping",
        component: () => import("@/views/Training_Institute/InstituteMap.vue"),
        children: [
          {
            path: "course",
            name: "map course component",
            component: () =>
                import("@/views/Training_Institute/mapping/MapCourse.vue"),
          },
          {
            path: "trainer",
            name: "map trainer component",
            component: () =>
                import("@/views/Training_Institute/mapping/MapTrainer.vue"),
          },
          {
            path: "assessor",
            name: "map assessor component",
            component: () =>
                import("@/views/Training_Institute/mapping/MapAssessor.vue"),
          },
        ],
      },

      {
        path: "institute-list/:id",
        name: "institute view",
        component: () =>
            import("@/views/Training_Institute/singleInstitute.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
